import { Mass, Measure, kilograms, pounds } from 'safe-units';

import { longTons, shortTons } from './constants';
import { WeightUnit } from './enums';

export const createMass = (weight: number, unit: WeightUnit): Mass => {
  switch (unit) {
    case WeightUnit.KILOGRAM:
      return Measure.of(weight, kilograms);
    case WeightUnit.POUND:
      return Measure.of(weight, pounds);
    case WeightUnit.LONG_TON:
      return Measure.of(weight, longTons);
    case WeightUnit.SHORT_TON:
      return Measure.of(weight, shortTons);
    default:
      throw new Error(`Unsupported weight unit: ${unit}`);
  }
};
