'use client';

import { useState } from 'react';

import { Form, Loading, Modal, Stack, TextInput } from '@carbon/react';

import { UserPhoneItemFragment } from '#fragments';
import { useVerifyPhoneVerificationCodeMutation } from './_generated';

type VerifyPhoneProps = {
  phone: UserPhoneItemFragment;
  onClose: () => void;
};

export const VerifyPhone: React.FunctionComponent<VerifyPhoneProps> = ({
  phone,
  onClose,
}) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState<Error>(null);

  const [verifyPhoneVerificationCode, { loading }] =
    useVerifyPhoneVerificationCodeMutation();

  return (
    <>
      <Loading withOverlay active={loading}></Loading>

      <Modal
        open={true}
        preventCloseOnClickOutside
        size="xs"
        modalHeading="Verify your mobile phone number"
        primaryButtonText="Verify"
        onRequestClose={() => {
          onClose();
        }}
        onRequestSubmit={async () => {
          try {
            setError(null);
            await verifyPhoneVerificationCode({
              variables: {
                code,
              },
            });

            onClose();
          } catch (error) {
            setError(error);
          }
        }}
      >
        <Form>
          <Stack gap={6}>
            <div>
              <p>
                Enter the 6 digit verification code sent to{' '}
                <span className="ww--no-wrap">{phone.formattedNumber}</span>
              </p>
            </div>

            <TextInput
              id="verificationCode"
              labelText="Verification code"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
              invalid={!!error}
              invalidText={'Could not verify code. Please try again'}
            />
          </Stack>
        </Form>
      </Modal>
    </>
  );
};
