import { Force, newtons } from 'safe-units';

import { poundForce } from './constants';
import { createForce } from './createForce';
import { ForceUnit } from './enums';

const convertForceTo = (force: Force, to: ForceUnit): number => {
  switch (to) {
    case ForceUnit.NEWTON:
      return force.over(newtons).value;
    case ForceUnit.POUND_FORCE:
      return force.over(poundForce).value;
    default:
      throw new Error(`Unsupported force unit: ${to}`);
  }
};

export const convertForce = (
  force: number,
  from: ForceUnit,
  to: ForceUnit,
): number => {
  const genericForce = createForce(force, from);
  const result = convertForceTo(genericForce, to);

  return Math.round(result * 10) / 10;
};
