'use client';

import { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import {
  UserAvatar,
  Settings,
  Document,
  CustomerService,
} from '@carbon/icons-react';
import {
  Header as CarbonHeader,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  Loading,
} from '@carbon/react';
import { Permission, canForAccount } from '@wastewizer/authz';
import { ConfirmationModal, SidePane } from '@wastewizer/ui-components';
import { usePathname } from 'next/navigation';

import { UserTheme } from '#generated-types';
import { useUser } from '#hooks/useUser';
import {
  useCreateServiceRequestMutation,
  useGetCurrentUserQuery,
  useSendPhoneVerificationCodeMutation,
  useUpdateCurrentUserMutation,
  useUpdateCurrentUserPreferencesMutation,
} from './_generated';
import styles from './Header.module.scss';
import { Account } from '../Account';
import { AddServiceRequest } from '../AddServiceRequest';
import { HeaderMenu } from '../HeaderMenu';
import { UserSettings } from '../UserSettings';
import { VerifyPhone } from '../VerifyPhone';

export const Header: React.FunctionComponent = () => {
  const apolloClient = useApolloClient();
  const user = useUser();
  const { getAccessTokenSilently } = useAuth0();
  const pathname = usePathname();

  const [supportAccounts, setSupportAccounts] = useState([]);
  const [showGlobalAction, setShowGlobalAction] = useState<{
    action:
      | 'support'
      | 'settings'
      | 'account'
      | 'phoneVerification'
      | 'confirmation';
    data?: string;
  } | null>(null);

  const { data: userData } = useGetCurrentUserQuery();

  useEffect(() => {
    const accountRoles = userData?.currentUser.accountRoles.reduce(
      (acc, ar) => {
        if (
          !!ar.account.salesforceId &&
          canForAccount(
            user,
            ar.account.id,
            Permission.CAN_CREATE_SERVICE_REQUEST,
          )
        ) {
          acc.push({
            id: ar.account.id,
            name: ar.account.name,
          });
        }

        return acc;
      },
      [],
    );

    setSupportAccounts(accountRoles || []);
  }, [userData, user]);

  const [createServiceRequest, { loading: createServiceRequestLoading }] =
    useCreateServiceRequestMutation();

  const [
    updateCurrentUserPreferences,
    { loading: updateCurrentUserPreferencesLoading },
  ] = useUpdateCurrentUserPreferencesMutation();

  const [updateCurrentUser, { loading: updateCurrentUserLoading }] =
    useUpdateCurrentUserMutation();

  const [sendPhoneVerificationCode] = useSendPhoneVerificationCodeMutation();

  return (
    <>
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <CarbonHeader aria-label="Client application">
            <SkipToContent href={`${pathname}#main-content`} />
            <HeaderMenuButton
              aria-label={isSideNavExpanded ? 'Close menu' : 'Open menu'}
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
              aria-expanded={isSideNavExpanded}
            />
            <HeaderName href="/" prefix="">
              <img
                src={
                  user?.preferences?.theme === UserTheme.Dark
                    ? '/logos/ssg-dark.png'
                    : '/logos/ssg-light.png'
                }
                alt="Scalable Systems Group"
                className={styles.logo}
              />
            </HeaderName>
            <HeaderNavigation aria-label="Client application">
              <HeaderMenu />
            </HeaderNavigation>
            <HeaderGlobalBar>
              {supportAccounts.length > 0 && (
                <HeaderGlobalAction
                  aria-label="Support"
                  onClick={() => setShowGlobalAction({ action: 'support' })}
                  tooltipAlignment="end"
                >
                  <CustomerService size={20} />
                </HeaderGlobalAction>
              )}

              <HeaderGlobalAction
                aria-label="Docs"
                tooltipAlignment="end"
                onClick={() => {
                  window.open(
                    'https://help.scalable-systems.io/docs/category/binbar',
                    '_blank',
                  );
                }}
              >
                <Document size={20} />
              </HeaderGlobalAction>

              <HeaderGlobalAction
                aria-label="My Settings"
                onClick={() => setShowGlobalAction({ action: 'settings' })}
                tooltipAlignment="end"
              >
                <Settings size={20} />
              </HeaderGlobalAction>

              <HeaderGlobalAction
                aria-label="My Account"
                onClick={() => setShowGlobalAction({ action: 'account' })}
                tooltipAlignment="end"
              >
                <UserAvatar size={20} />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
            <SideNav
              aria-label="Side navigation"
              expanded={isSideNavExpanded}
              isPersistent={false}
              onSideNavBlur={onClickSideNavExpand}
            >
              <SideNavItems>
                <HeaderSideNavItems>
                  <HeaderMenu onClickSideNavExpand={onClickSideNavExpand} />
                </HeaderSideNavItems>
              </SideNavItems>
            </SideNav>
          </CarbonHeader>
        )}
      />

      <Loading
        withOverlay
        active={
          createServiceRequestLoading ||
          updateCurrentUserPreferencesLoading ||
          updateCurrentUserLoading
        }
      />

      <SidePane
        open={
          showGlobalAction?.action === 'settings' ||
          showGlobalAction?.action === 'account'
        }
        onClose={() => setShowGlobalAction(null)}
      >
        {showGlobalAction?.action === 'settings' && !!userData && (
          <UserSettings
            user={user}
            phone={userData.currentUser.phone}
            onSettingsChange={async (theme, weightUnit, weightDisplay) => {
              await updateCurrentUserPreferences({
                variables: {
                  theme,
                  weightUnit,
                  weightDisplay,
                },
              });
              await getAccessTokenSilently({ cacheMode: 'off' });
              await apolloClient.resetStore();
            }}
            onPhoneChange={async (phoneNumber) => {
              await updateCurrentUser({
                variables: {
                  input: {
                    phoneNumber,
                  },
                },
              });
            }}
            onPhoneVerifyClick={async () => {
              await sendPhoneVerificationCode();
              setShowGlobalAction({ action: 'phoneVerification' });
            }}
          />
        )}

        {showGlobalAction?.action === 'account' && !!userData && (
          <Account
            name={userData.currentUser.name}
            email={userData.currentUser.email}
          />
        )}
      </SidePane>

      {showGlobalAction?.action === 'support' && (
        <AddServiceRequest
          accounts={supportAccounts}
          onClose={() => setShowGlobalAction(null)}
          onAdd={async (input) => {
            setShowGlobalAction(null);
            const {
              data: {
                createServiceRequest: { id },
              },
            } = await createServiceRequest({
              variables: {
                input,
              },
            });
            setShowGlobalAction({ action: 'confirmation', data: id });
          }}
        />
      )}

      {showGlobalAction?.action === 'phoneVerification' && !!userData && (
        <VerifyPhone
          phone={userData.currentUser.phone}
          onClose={() => setShowGlobalAction({ action: 'settings' })}
        />
      )}

      {showGlobalAction?.action === 'confirmation' && (
        <ConfirmationModal
          heading={
            <>
              Your request has been created successfully. An account manager
              will be in touch with you shortly.
            </>
          }
          body={<strong>Reference # {showGlobalAction.data}</strong>}
          onClose={() => {
            setShowGlobalAction(null);
          }}
        />
      )}
    </>
  );
};
