'use client';

import { ReactNode, useEffect } from 'react';

import { useApolloClient } from '@apollo/client';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ImpersonatingBanner, SuperUserOnly } from '@wastewizer/ui-components';
import { LocalStorageKey } from '@wastewizer/ui-constants';
import { notFound, useRouter } from 'next/navigation';
import { useLocalStorage } from 'usehooks-ts';

import { User, UserTheme } from '#generated-types';
import { useUser } from '#hooks/useUser';
import styles from './Layout.module.scss';
import { Header } from '../Header';

type LayoutProps = {
  children: ReactNode;
  superUserOnly?: boolean;
};

export const Layout: React.FunctionComponent<LayoutProps> =
  withAuthenticationRequired(({ superUserOnly = false, children }) => {
    const user = useUser();
    const apolloClient = useApolloClient();
    const router = useRouter();

    const [impersonation, setImpersonation] = useLocalStorage<User>(
      LocalStorageKey.IMPERSONATION,
      null,
    );

    useEffect(() => {
      document.documentElement.setAttribute(
        'data-carbon-theme',
        (user?.preferences.theme || UserTheme.Dark).toLowerCase(),
      );
    }, [user]);

    return (
      <>
        <Header />
        <main id="main-content" className={styles.appContent}>
          <ImpersonatingBanner
            impersonationEmail={impersonation?.email}
            onEndImpersonationClick={async () => {
              setImpersonation(null);
              await apolloClient.clearStore();
              router.push('/');
            }}
          />
          {superUserOnly ? (
            <SuperUserOnly
              user={user}
              onReject={() => {
                if (!user?.email) {
                  return null; // still fetching user from Auth0
                }

                notFound();
              }}
            >
              {children}
            </SuperUserOnly>
          ) : (
            children
          )}
        </main>
      </>
    );
  });
