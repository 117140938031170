import { Force, Measure, newtons } from 'safe-units';

import { poundForce } from './constants';
import { ForceUnit } from './enums';

export const createForce = (force: number, unit: ForceUnit): Force => {
  switch (unit) {
    case ForceUnit.NEWTON:
      return Measure.of(force, newtons);
    case ForceUnit.POUND_FORCE:
      return Measure.of(force, poundForce);
    default:
      throw new Error(`Unsupported force unit: ${unit}`);
  }
};
