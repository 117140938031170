import { User } from '@wastewizer/authz';

import { UserTheme, WeightDisplay, WeightUnit } from '#generated-types';
import { WeightLabel } from './WeightLabel';
import { getWeightLabel } from '../utils/getWeightLabel';

export type UserWithPreferences = User & {
  preferences: {
    theme?: UserTheme;
    weightUnit?: WeightUnit;
    weightLabel?: WeightLabel;
    weightDisplay?: WeightDisplay;
  };
};

export const defaultUserWithPreferences: UserWithPreferences = {
  appRoles: [],
  accountRoles: [],
  preferences: {
    theme: UserTheme.Light,
    weightUnit: WeightUnit.ShortTon,
    weightLabel: getWeightLabel(WeightUnit.ShortTon),
    weightDisplay: WeightDisplay.Gross,
  },
} as UserWithPreferences;
