export const roundWeight = (
  value: number,
  precision: number,
  toNearest?: number,
) => {
  const multiplier = Math.pow(10, precision);

  if (toNearest) {
    return (
      Math.round(Math.round(value / toNearest) * toNearest * multiplier) /
      multiplier
    );
  }

  return Math.round(value * multiplier) / multiplier;
};
