import * as Types from '../../../_generated-types/index';

import { gql } from '@apollo/client';
import { UserPhoneItemFragmentDoc } from '../../../_generated/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CreateServiceRequestDocument = gql`
  mutation CreateServiceRequest($input: CreateServiceRequestInput!) {
    createServiceRequest(input: $input) {
      id
    }
  }
`;
export type CreateServiceRequestMutationFn = Apollo.MutationFunction<
  CreateServiceRequestMutation,
  CreateServiceRequestMutationVariables
>;

/**
 * __useCreateServiceRequestMutation__
 *
 * To run a mutation, you first call `useCreateServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceRequestMutation, { data, loading, error }] = useCreateServiceRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceRequestMutation,
    CreateServiceRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceRequestMutation,
    CreateServiceRequestMutationVariables
  >(CreateServiceRequestDocument, options);
}
export type CreateServiceRequestMutationHookResult = ReturnType<
  typeof useCreateServiceRequestMutation
>;
export type CreateServiceRequestMutationResult =
  Apollo.MutationResult<CreateServiceRequestMutation>;
export type CreateServiceRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceRequestMutation,
  CreateServiceRequestMutationVariables
>;
export const UpdateCurrentUserDocument = gql`
  mutation UpdateCurrentUser($input: UpdateCurrentUserInput!) {
    updateCurrentUser(input: $input) {
      id
      phone {
        ...UserPhoneItem
      }
    }
  }
  ${UserPhoneItemFragmentDoc}
`;
export type UpdateCurrentUserMutationFn = Apollo.MutationFunction<
  UpdateCurrentUserMutation,
  UpdateCurrentUserMutationVariables
>;

/**
 * __useUpdateCurrentUserMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserMutation, { data, loading, error }] = useUpdateCurrentUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserMutation,
    UpdateCurrentUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserMutation,
    UpdateCurrentUserMutationVariables
  >(UpdateCurrentUserDocument, options);
}
export type UpdateCurrentUserMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserMutation
>;
export type UpdateCurrentUserMutationResult =
  Apollo.MutationResult<UpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentUserMutation,
  UpdateCurrentUserMutationVariables
>;
export const SendPhoneVerificationCodeDocument = gql`
  mutation SendPhoneVerificationCode {
    sendPhoneVerificationCode {
      id
    }
  }
`;
export type SendPhoneVerificationCodeMutationFn = Apollo.MutationFunction<
  SendPhoneVerificationCodeMutation,
  SendPhoneVerificationCodeMutationVariables
>;

/**
 * __useSendPhoneVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendPhoneVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPhoneVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPhoneVerificationCodeMutation, { data, loading, error }] = useSendPhoneVerificationCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendPhoneVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPhoneVerificationCodeMutation,
    SendPhoneVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPhoneVerificationCodeMutation,
    SendPhoneVerificationCodeMutationVariables
  >(SendPhoneVerificationCodeDocument, options);
}
export type SendPhoneVerificationCodeMutationHookResult = ReturnType<
  typeof useSendPhoneVerificationCodeMutation
>;
export type SendPhoneVerificationCodeMutationResult =
  Apollo.MutationResult<SendPhoneVerificationCodeMutation>;
export type SendPhoneVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    SendPhoneVerificationCodeMutation,
    SendPhoneVerificationCodeMutationVariables
  >;
export const UpdateCurrentUserPreferencesDocument = gql`
  mutation UpdateCurrentUserPreferences(
    $theme: UserTheme!
    $weightUnit: WeightUnit!
    $weightDisplay: WeightDisplay!
  ) {
    updateCurrentUserPreferences(
      theme: $theme
      weightUnit: $weightUnit
      weightDisplay: $weightDisplay
    ) {
      id
    }
  }
`;
export type UpdateCurrentUserPreferencesMutationFn = Apollo.MutationFunction<
  UpdateCurrentUserPreferencesMutation,
  UpdateCurrentUserPreferencesMutationVariables
>;

/**
 * __useUpdateCurrentUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserPreferencesMutation, { data, loading, error }] = useUpdateCurrentUserPreferencesMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *      weightUnit: // value for 'weightUnit'
 *      weightDisplay: // value for 'weightDisplay'
 *   },
 * });
 */
export function useUpdateCurrentUserPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserPreferencesMutation,
    UpdateCurrentUserPreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserPreferencesMutation,
    UpdateCurrentUserPreferencesMutationVariables
  >(UpdateCurrentUserPreferencesDocument, options);
}
export type UpdateCurrentUserPreferencesMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserPreferencesMutation
>;
export type UpdateCurrentUserPreferencesMutationResult =
  Apollo.MutationResult<UpdateCurrentUserPreferencesMutation>;
export type UpdateCurrentUserPreferencesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentUserPreferencesMutation,
    UpdateCurrentUserPreferencesMutationVariables
  >;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser {
      id
      name
      email
      phone {
        ...UserPhoneItem
      }
      accountRoles {
        account {
          id
          name
          salesforceId
        }
      }
    }
  }
  ${UserPhoneItemFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export type CreateServiceRequestMutationVariables = Types.Exact<{
  input: Types.CreateServiceRequestInput;
}>;

export type CreateServiceRequestMutation = {
  __typename?: 'Mutation';
  createServiceRequest: { __typename?: 'ServiceRequest'; id: string };
};

export type UpdateCurrentUserMutationVariables = Types.Exact<{
  input: Types.UpdateCurrentUserInput;
}>;

export type UpdateCurrentUserMutation = {
  __typename?: 'Mutation';
  updateCurrentUser: {
    __typename?: 'User';
    id: string;
    phone?: {
      __typename?: 'UserPhone';
      number: string;
      formattedNumber?: string | null;
      isVerified: boolean;
    } | null;
  };
};

export type SendPhoneVerificationCodeMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SendPhoneVerificationCodeMutation = {
  __typename?: 'Mutation';
  sendPhoneVerificationCode: { __typename?: 'User'; id: string };
};

export type UpdateCurrentUserPreferencesMutationVariables = Types.Exact<{
  theme: Types.UserTheme;
  weightUnit: Types.WeightUnit;
  weightDisplay: Types.WeightDisplay;
}>;

export type UpdateCurrentUserPreferencesMutation = {
  __typename?: 'Mutation';
  updateCurrentUserPreferences: { __typename?: 'User'; id: string };
};

export type GetCurrentUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: {
      __typename?: 'UserPhone';
      number: string;
      formattedNumber?: string | null;
      isVerified: boolean;
    } | null;
    accountRoles: Array<{
      __typename?: 'UserAccountRole';
      account: {
        __typename?: 'Account';
        id: string;
        name: string;
        salesforceId?: string | null;
      };
    }>;
  };
};
