'use client';

import { useState } from 'react';

import { CheckmarkOutline } from '@carbon/icons-react';
import {
  Button,
  Dropdown,
  FormLabel,
  Stack,
  TextInput,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
} from '@carbon/react';
import { maskitoTransform } from '@maskito/core';
import { maskitoPhoneOptionsGenerator } from '@maskito/phone';
import { useMaskito } from '@maskito/react';
import metadata from 'libphonenumber-js/min/metadata';

import { UserPhoneItemFragment } from '#fragments';
import { UserTheme, WeightDisplay, WeightUnit } from '#generated-types';
import { UserWithPreferences } from '#types/UserWithPreferences';
import { getWeightLabel } from '#utils/getWeightLabel';
import styles from './UserSettings.module.scss';

const phoneOptions = maskitoPhoneOptionsGenerator({
  countryIsoCode: 'US',
  metadata,
});
const phoneRegEx = /^\+[1-9]{1}[0-9]{3}[0-9]{3}[0-9]{4}$/;

type UserSettingsProps = {
  user: UserWithPreferences;
  phone?: UserPhoneItemFragment;
  onSettingsChange: (
    theme: UserTheme,
    weightUnit: WeightUnit,
    weightDisplay: WeightDisplay,
  ) => void;
  onPhoneChange: (phoneNumber: string) => void;
  onPhoneVerifyClick: () => void;
};

export const UserSettings: React.FunctionComponent<UserSettingsProps> = ({
  user,
  phone,
  onSettingsChange,
  onPhoneChange,
  onPhoneVerifyClick,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(phone?.number);
  const maskedInputRef = useMaskito({ options: phoneOptions });

  const themes = Object.values(UserTheme).map((t) => ({
    id: t,
    name: t.toLowerCase(),
  }));

  const weightUnits = Object.values(WeightUnit).map((w) => {
    const labels = getWeightLabel(w);
    return {
      id: w,
      name: `${labels.plural} (${labels.singularAbbrev})`,
    };
  });

  const weightDisplays = Object.values(WeightDisplay).map((w) => ({
    id: w,
    name: w.toLowerCase(),
  }));

  return (
    <Stack gap={6}>
      <Dropdown
        id="theme-dropdown"
        titleText="Theme"
        helperText="Select your preferred theme"
        label="Theme"
        items={themes}
        itemToString={(item) => item.name}
        initialSelectedItem={
          themes.find((t) => t.id === user.preferences.theme) ||
          themes.find((t) => t.id === UserTheme.Light)
        }
        onChange={(item) => {
          onSettingsChange(
            item.selectedItem.id,
            user.preferences.weightUnit,
            user.preferences.weightDisplay,
          );
        }}
      />
      <Dropdown
        id="weight-unit-dropdown"
        titleText="Weight unit"
        helperText="Select the unit you want to use for displayed weights"
        label="Weight unit"
        items={weightUnits}
        initialSelectedItem={
          weightUnits.find((w) => w.id === user.preferences.weightUnit) ||
          weightUnits.find((w) => w.id === WeightUnit.ShortTon)
        }
        itemToString={(item) => item.name}
        onChange={(item) => {
          onSettingsChange(
            user.preferences.theme,
            item.selectedItem.id,
            user.preferences.weightDisplay,
          );
        }}
      />
      <Dropdown
        id="weight-display-dropdown"
        titleText="Weight display"
        helperText="Select how you would like to see weights displayed"
        label="Weight display"
        items={weightDisplays}
        initialSelectedItem={
          weightDisplays.find((w) => w.id === user.preferences.weightDisplay) ||
          weightDisplays.find((w) => w.id === WeightDisplay.Gross)
        }
        itemToString={(item) => item.name}
        onChange={(item) => {
          onSettingsChange(
            user.preferences.theme,
            user.preferences.weightUnit,
            item.selectedItem.id,
          );
        }}
      />

      <div className={styles.mobilePhone}>
        <FormLabel>Mobile phone</FormLabel>
        <div className={styles.verify}>
          <TextInput
            ref={maskedInputRef}
            id="phoneNumber"
            labelText="Mobile phone number"
            hideLabel
            helperText="Enter and verify a mobile phone number for SMS notifications"
            defaultValue={
              phone?.number ? maskitoTransform(phone.number, phoneOptions) : ''
            }
            onInput={(e) => {
              const value = (e.target as HTMLInputElement).value;
              setPhoneNumber(value.replace(/[- )(]/g, ''));
            }}
            onBlur={() => {
              if (
                phoneRegEx.test(phoneNumber) &&
                phoneNumber !== phone?.number
              ) {
                onPhoneChange(phoneNumber);
              }
            }}
            invalid={
              phoneNumber?.length > 2 && phoneRegEx.test(phoneNumber) === false
            }
            invalidText="Please enter a valid US mobile phone number"
          />

          {phoneNumber === phone?.number && phone?.isVerified ? (
            <Toggletip align="bottom-right">
              <ToggletipButton label="Show information">
                <CheckmarkOutline size={24} title="Verified" />
              </ToggletipButton>
              <ToggletipContent>
                <p>
                  This mobile phone number has been verified. You can now
                  subscribe to SMS notifications
                </p>
              </ToggletipContent>
            </Toggletip>
          ) : (
            <Button
              type="button"
              size="md"
              kind="tertiary"
              onClick={onPhoneVerifyClick}
              disabled={phoneRegEx.test(phoneNumber) === false}
            >
              Verify
            </Button>
          )}
        </div>
      </div>
    </Stack>
  );
};
