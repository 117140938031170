import { Mass, kilograms, pounds } from 'safe-units';

import { longTons, shortTons } from './constants';
import { createMass } from './createMass';
import { WeightUnit } from './enums';

const convertMassTo = (weight: Mass, to: WeightUnit): number => {
  switch (to) {
    case WeightUnit.KILOGRAM:
      return weight.over(kilograms).value;
    case WeightUnit.POUND:
      return weight.over(pounds).value;
    case WeightUnit.LONG_TON:
      return weight.over(longTons).value;
    case WeightUnit.SHORT_TON:
      return weight.over(shortTons).value;
    default:
      throw new Error(`Unsupported weight unit: ${to}`);
  }
};

export const convertWeight = (
  weight: number,
  from: WeightUnit,
  to: WeightUnit,
): number => {
  const mass = createMass(weight, from);
  const result = convertMassTo(mass, to);

  return Math.round(result * 10) / 10;
};
