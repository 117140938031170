'use client';

import { useAuth0 } from '@auth0/auth0-react';
import { Logout } from '@carbon/icons-react';
import { Button, Stack } from '@carbon/react';
import { LocalStorageKey } from '@wastewizer/ui-constants';

import { useTrackLogoutMutation } from './_generated';
import styles from './Account.module.scss';

type AccountProps = {
  name: string;
  email: string;
};

export const Account: React.FunctionComponent<AccountProps> = ({
  name,
  email,
}) => {
  const { logout } = useAuth0();
  const [trackLogout] = useTrackLogoutMutation();

  return (
    <Stack gap={6}>
      <div>
        <span className={styles.title}>My account</span>
      </div>
      <div className={styles.centered}>
        <p>{name}</p>
        <p>{email}</p>
      </div>
      <div className={styles.centered}>
        <Button
          type="button"
          renderIcon={Logout}
          onClick={async () => {
            window.localStorage.removeItem(LocalStorageKey.IMPERSONATION);
            await trackLogout();
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }}
        >
          Logout
        </Button>
      </div>
    </Stack>
  );
};
