import { createContext } from 'react';

import {
  defaultUserWithPreferences,
  UserWithPreferences,
} from '../types/UserWithPreferences';

export const UserContext = createContext<UserWithPreferences>(
  defaultUserWithPreferences,
);
