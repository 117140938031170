'use client';

import { HeaderMenu as CarbonHeaderMenu, HeaderMenuItem } from '@carbon/react';
import { useGateValue } from '@statsig/react-bindings';
import { SuperUserOnly } from '@wastewizer/ui-components';
import { usePathname, useRouter } from 'next/navigation';

import { useUser } from '#hooks/useUser';

type HeaderMenuProps = {
  onClickSideNavExpand?: () => void;
};

export const HeaderMenu: React.FunctionComponent<HeaderMenuProps> = ({
  onClickSideNavExpand,
}) => {
  const user = useUser();
  const pathname = usePathname();
  const router = useRouter();
  const canSeeReports = useGateValue('report_access');

  const onClick = (to: string) => {
    if (onClickSideNavExpand) {
      onClickSideNavExpand();
    }
    router.push(to);
  };

  return (
    <>
      <HeaderMenuItem
        onClick={() => onClick('/service-locations')}
        isActive={pathname.startsWith('/service-locations')}
      >
        Service locations
      </HeaderMenuItem>
      <HeaderMenuItem
        onClick={() => onClick('/container-sites')}
        isActive={pathname.startsWith('/container-sites')}
      >
        Container sites
      </HeaderMenuItem>
      {canSeeReports && (
        <HeaderMenuItem
          onClick={() => onClick('/reports')}
          isActive={pathname.startsWith('/reports')}
        >
          Reports
        </HeaderMenuItem>
      )}
      <SuperUserOnly user={user} onReject={() => null}>
        <CarbonHeaderMenu aria-label="Admin" menuLinkName="Admin">
          <HeaderMenuItem onClick={() => onClick('/admin/accounts')}>
            Accounts
          </HeaderMenuItem>
          <HeaderMenuItem onClick={() => onClick('/admin/users')}>
            Users
          </HeaderMenuItem>
          <HeaderMenuItem onClick={() => onClick('/admin/binbars')}>
            BinBars
          </HeaderMenuItem>
          <HeaderMenuItem onClick={() => onClick('/admin/container-sites')}>
            Container sites
          </HeaderMenuItem>
          <HeaderMenuItem onClick={() => onClick('/admin/alerts')}>
            Alerts
          </HeaderMenuItem>
        </CarbonHeaderMenu>
      </SuperUserOnly>
    </>
  );
};
