import {
  barbaraV1Max,
  barbaraV1Min,
  longTons,
  shortTons,
} from '@wastewizer/weight-utils';
import { pounds, kilograms } from 'safe-units';

import { WeightUnit } from '#generated-types';
import { WeightLabel } from '../types/WeightLabel';

export const getWeightLabel = (unit: WeightUnit): WeightLabel => {
  switch (unit) {
    case WeightUnit.Kilogram:
      return {
        singular: 'kilogram',
        plural: 'kilograms',
        singularAbbrev: 'kg',
        pluralAbbrev: 'kg',
        key: WeightUnit.Kilogram,
        barbaraV1Min: barbaraV1Min.over(kilograms).value,
        barbaraV1Max: barbaraV1Max.over(kilograms).value,
      };
    case WeightUnit.Pound:
      return {
        singular: 'pound',
        plural: 'pounds',
        singularAbbrev: 'lb',
        pluralAbbrev: 'lbs',
        key: WeightUnit.Pound,
        barbaraV1Min: barbaraV1Min.over(pounds).value,
        barbaraV1Max: barbaraV1Max.over(pounds).value,
      };
    case WeightUnit.LongTon:
      return {
        singular: 'long ton',
        plural: 'long tons',
        singularAbbrev: 'l/t',
        pluralAbbrev: 'l/t',
        key: WeightUnit.LongTon,
        barbaraV1Min: barbaraV1Min.over(longTons).value,
        barbaraV1Max: barbaraV1Max.over(longTons).value,
      };
    case WeightUnit.ShortTon:
      return {
        singular: 'ton',
        plural: 'tons',
        singularAbbrev: 't',
        pluralAbbrev: 't',
        key: WeightUnit.ShortTon,
        barbaraV1Min: barbaraV1Min.over(shortTons).value,
        barbaraV1Max: barbaraV1Max.over(shortTons).value,
      };
    default:
      throw new Error(`Unknown weight unit ${JSON.stringify(unit)}`);
  }
};
