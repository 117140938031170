import * as Types from '../../../_generated-types/index';

import { gql } from '@apollo/client';
import { UserPhoneItemFragmentDoc } from '../../../_generated/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const VerifyPhoneVerificationCodeDocument = gql`
  mutation VerifyPhoneVerificationCode($code: String!) {
    verifyPhoneVerificationCode(code: $code) {
      id
      phone {
        ...UserPhoneItem
      }
    }
  }
  ${UserPhoneItemFragmentDoc}
`;
export type VerifyPhoneVerificationCodeMutationFn = Apollo.MutationFunction<
  VerifyPhoneVerificationCodeMutation,
  VerifyPhoneVerificationCodeMutationVariables
>;

/**
 * __useVerifyPhoneVerificationCodeMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneVerificationCodeMutation, { data, loading, error }] = useVerifyPhoneVerificationCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyPhoneVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyPhoneVerificationCodeMutation,
    VerifyPhoneVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyPhoneVerificationCodeMutation,
    VerifyPhoneVerificationCodeMutationVariables
  >(VerifyPhoneVerificationCodeDocument, options);
}
export type VerifyPhoneVerificationCodeMutationHookResult = ReturnType<
  typeof useVerifyPhoneVerificationCodeMutation
>;
export type VerifyPhoneVerificationCodeMutationResult =
  Apollo.MutationResult<VerifyPhoneVerificationCodeMutation>;
export type VerifyPhoneVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    VerifyPhoneVerificationCodeMutation,
    VerifyPhoneVerificationCodeMutationVariables
  >;
export type VerifyPhoneVerificationCodeMutationVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;

export type VerifyPhoneVerificationCodeMutation = {
  __typename?: 'Mutation';
  verifyPhoneVerificationCode: {
    __typename?: 'User';
    id: string;
    phone?: {
      __typename?: 'UserPhone';
      number: string;
      formattedNumber?: string | null;
      isVerified: boolean;
    } | null;
  };
};
