import * as Types from '../../../_generated-types/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TrackLogoutDocument = gql`
  mutation TrackLogout {
    createUserEvent(input: { event: LOGOUT }) {
      id
    }
  }
`;
export type TrackLogoutMutationFn = Apollo.MutationFunction<
  TrackLogoutMutation,
  TrackLogoutMutationVariables
>;

/**
 * __useTrackLogoutMutation__
 *
 * To run a mutation, you first call `useTrackLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackLogoutMutation, { data, loading, error }] = useTrackLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useTrackLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackLogoutMutation,
    TrackLogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrackLogoutMutation, TrackLogoutMutationVariables>(
    TrackLogoutDocument,
    options,
  );
}
export type TrackLogoutMutationHookResult = ReturnType<
  typeof useTrackLogoutMutation
>;
export type TrackLogoutMutationResult =
  Apollo.MutationResult<TrackLogoutMutation>;
export type TrackLogoutMutationOptions = Apollo.BaseMutationOptions<
  TrackLogoutMutation,
  TrackLogoutMutationVariables
>;
export type TrackLogoutMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type TrackLogoutMutation = {
  __typename?: 'Mutation';
  createUserEvent: { __typename?: 'UserEvent'; id: string };
};
